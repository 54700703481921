import https from "./https"
import store from "@/store"

const guardPushMessageApi = {
  store (params) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/guard-push-messages`

    return https.post(url, params)
  },
}

export default guardPushMessageApi
