<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>群組廣播</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                群組廣播
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="群組"
                >
                  <b-form-select v-model="selectedGroupId" :disabled="!!outerCodes">
                    <b-form-select-option :value="null" disabled>
                      選擇群組
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="group in groups"
                      :value="group.guard_group_id"
                      :key="group.guard_group_id"
                    >
                      {{ group.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="群組們"
                >
                  <b-textarea
                    class="form-control"
                    rows="5"
                    placeholder="輸入 outer code"
                    v-model="outerCodes"
                  ></b-textarea>
                </b-form-group>

                <!-- <b-form-group -->
                <!--   label-cols="12" -->
                <!--   label-cols-lg="2" -->
                <!--   label-size="sm" -->
                <!--   label="*發送時間" -->
                <!-- > -->
                <!--   <div class="row"> -->
                <!--     <div class="col-12 col-xl-6"> -->
                <!--       <datepicker -->
                <!--         placeholder="Select Date" -->
                <!--         v-model="notify_at.date" -->
                <!--         bootstrap-styling -->
                <!--         format="yyyy-MM-dd" -->
                <!--         :language="zh" -->
                <!--         :disabledDates="disabledDates" -->
                <!--       ></datepicker> -->
                <!--       <b-form-invalid-feedback -->
                <!--         :state="!v$.notify_at.date.required.$error" -->
                <!--       > -->
                <!--         此欄位為必填 -->
                <!--       </b-form-invalid-feedback> -->
                <!--     </div> -->
                <!--  -->
                <!--     <div class="col-12 col-xl-6"> -->
                <!--       <vue-timepicker -->
                <!--         format="HH:mm" -->
                <!--         v-model="notify_at.time" -->
                <!--         :input-class="['form-control']" -->
                <!--       ></vue-timepicker> -->
                <!--       <b-form-invalid-feedback -->
                <!--         :state=" -->
                <!--           !v$.notify_at.time.HH.$error || -->
                <!--           !v$.notify_at.time.mm.$error -->
                <!--         " -->
                <!--       > -->
                <!--         此欄位為必填 -->
                <!--       </b-form-invalid-feedback> -->
                <!--     </div> -->
                <!--   </div> -->
                <!-- </b-form-group> -->

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*發送內容"
                >
                  <b-form-textarea
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.message.$error ? false : null"
                    v-model="message"
                    rows="8"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    :state="!v$.message.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import guardApi from "@/apis/guard";
import guardPushMessageApi from "@/apis/guard-push-message";
import { subDays } from "date-fns";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      message: { required },
      // notify_at: {
      //   date: {
      //     required,
      //   },
      //   time: {
      //     HH: { required },
      //     mm: { required },
      //   },
      // },
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      validationErrors: null,
      disabledDates: { to: subDays(new Date(), 1) },
      // notify_at: {
      //   date: new Date(),
      //   time: {
      //     HH: "00",
      //     mm: "00",
      //   },
      // },
      groups: [],
      selectedGroupId: null,
      outerCodes: null,
      message: null,
    };
  },

  mounted() {
    this.fetchGuardGroups();
  },

  methods: {
    async fetchGuardGroups() {
      this.showLoading = true;

      try {
        const { data } = await guardApi.getActiveGuardGroups();
        this.groups = data.data;
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      let params = {
        message: this.message,
      }
      if (this.outerCodes) {
        params.outer_codes = this.outerCodes.split('\n');
      } else {
        params.group_id = this.selectedGroupId;
      }

      try {
        let response = await guardPushMessageApi.store(params);

        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "新增成功",
            type: "success",
          });

          this.refresh();
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else if (error.response.status === 400) {
          this.$swal("新增失敗", null, "error");
        } else {
          this.$swal.fire({
            title: "新增失敗",
            type: "error",
          });
        }
      }
    },
    refresh() {
      this.selectedGroupId = null;
      this.outerCodes = null;
      this.message = null;
      this.v$.$reset();
    },
    handleCancel() {
      this.$router.push({ name: "MainGroupbuyingsList" });
    },
  },
};
</script>
